* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: sans-serif;
  background: rgb(246, 247, 249);
  /* background: #12c2e9; */
  /* background: -webkit-linear-gradient(to right, #f64f59, #c471ed, #12c2e9); */
  /* background: linear-gradient(to right, #f64f59, #c471ed, #12c2e9); */
}

body,
html,
.App,
#root,
.outer {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.outer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.outer .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.outer h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.alert-fixed {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 9999;
  border-radius: 0px;
}
