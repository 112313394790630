.new-intention-card {
  min-height: 500px;
}

.new-intention-card-half {
  width: 50%;
}

.new-intention-button-group {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
  width: 100%;
}

.new-intention-button-spacer {
  width: 10px;
}

.new-intention-button-group Button {
  width: 100px;
}

.new-intention-intention-details-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.new-intention-available-mass-list-container {
  height: 200px;
  width: 400px;
  overflow-y: scroll;
}

.new-intention-intentions-list {
  padding-top: 20px;
  height: 400px;
  width: 350px;
  overflow-y: scroll;
}

.new-intention-payment-summary-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.new-intention-money::before {
  content: "$";
}

.new-intention-table-container {
  max-height: 300px;
  overflow-y: scroll;
}
