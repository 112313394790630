.bulletin-printout-report-container {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  text-align: center;
}

.bulletin-printout-report-day-header {
  font-size: 24px;
  font-weight: bold;
}

.bulletin-printout-report-intention-container {
  padding-top: 5px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.bulletin-printout-report-title {
  font-size: 30px;
}

.bulletin-printout-report-divider {
  width: 200px;
  height: 1px;
  background-color: black;
}

.bulletin-printout-report-days-container {
  margin-top: 20px;
}
