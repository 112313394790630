.action-bar-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
}

.action-bar-title {
  font-size: 28px;
  font-weight: bold;
}
