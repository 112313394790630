.intention-card-container {
  border: 1px solid;
  border-color: #ced4da;
  border-radius: 10px;
  min-height: 85px;
  width: 300px;
  padding-top: 16px;
  padding-left: 16px;
  box-shadow: 0px 10px 10px rgba(34, 35, 58, 0.05);
}

.intention-card-remove-circle {
  height: 30px;
  width: 30px;
  margin-top: -10px;
  background-color: white;
  float: right;
}

.intention-card-parent {
  height: 100px;
  width: 310px;
  margin-bottom: 5px;
}

.intention-card-deceased-icon {
  margin-top: -5px;
  font-weight: bold;
  font-size: 20px;
}
