.intentions-by-requestor-button-group {
  display: flex;
  flex-direction: row;
}

.intentions-by-requestor-button-group Button {
  width: 100px;
  margin-right: 10px;
}

.intentions-by-requestor-table-container {
  margin-top: 20px;
  max-height: 300px;
  overflow-y: scroll;
}

.intentions-by-requestor-options-list {
  height: 200px;
  width: 400px;
  overflow-y: scroll;
}

.intentions-by-requestor-search-card {
  width: 50%;
}

.intentions-by-requestor-search-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
