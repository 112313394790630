.calendar-container {
  border: 1px;
  border-style: solid;
  border-color: black;
  height: 300px;
  width: 300px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
}

.calendar-table tr {
  height: 35px;
  vertical-align: middle;
}

.calendar-table .day-row:hover {
  background-color: lightgray;
}

.calendar-table td {
  text-align: center;
}

.calendar-table .calendar-title-row {
  height: 30px;
  vertical-align: middle;
  font-weight: bold;
}

/* .calendar-table .day-name-row {
    height: 30px;
    vertical-align: middle;
}

.calendar-table .day-row {
    height: 30px;
} */

.calendar-table .unavailable {
  color: lightgray;
}

.calendar-table .available {
  background-color: lightgreen;
}

.calendar-available-date {
  background-color: white;
  cursor: pointer;
}

.calendar-unavailable-date {
  background-color: lightgray;
}
