.new-intention-button-group {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
  width: 100%;
}

.new-intention-button-spacer {
  width: 10px;
}

.unavailable-dates-card {
  min-height: 500px;
}
