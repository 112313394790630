/* .column-border {
  border-width: 1px;
  border-left-style: solid;
  border-right-style: none;
}

.column-border-left-outside {
  border-left-style: solid;
  border-left-width: 1px;
}

.column-border-right-outside {
  border-right-style: solid;
  border-right-width: 1px;
} */

table.day {
  /* margin: auto; */
}

table tr {
  vertical-align: top;
}

table.portrait-week-mass-list {
  width: 100%;
}

table.portrait-week-mass-list tr {
  border-top: 1px solid lightgray;
  height: 30px;
}

tr.spinner-row td {
  padding-left: 5px;
  padding-right: 5px;
  border-left-style: solid;
  border-left-color: transparent;
  border-left-width: 1px;
  border-right-style: solid;
  border-right-color: transparent;
  border-right-width: 1px;
}

.day-card {
  background-color: white;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

div.portrait-week-container {
  width: 100%;
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;
  background-color: #ededed;
}

.header-day-number {
  font-size: 36px;
}

.portrait-week-mass-row {
  padding-left: 10px;
  height: 100px;
}

.portrait-week-mass-row:hover {
  background-color: rgb(235, 235, 235);
  cursor: pointer;
}

.portrait-week-filler-row {
  cursor: pointer;
  padding-left: 10px;
}

.portrait-week-spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 100px;
}

.portrait-week-error {
  background-color: white;
  margin-bottom: 20px;
  width: 100%;
  height: 100px;
  display: flex;
  text-align: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.portrait-week-error-icon {
  font-size: 48px;
  color: red;
}
