.week-header-control {
  display: flex;
  flex-direction: row;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding-top: 5px;
  background-color: white;
  margin-bottom: 10px;
  align-self: center;
}

.week-header-last-refresh {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex: 1;
}
