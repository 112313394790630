.intentions-by-presider-button-group {
  display: flex;
  flex-direction: row;
}

.intentions-by-presider-button-group Button {
  width: 100px;
  margin-right: 10px;
}

.intentions-by-presider-table-container {
  margin-top: 20px;
  max-height: 300px;
  overflow-y: scroll;
}
