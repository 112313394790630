.new-recurring-mass-start-time-row {
  margin-top: 5px;
}

.new-recurring-mass-start-time-row .actions {
  visibility: hidden;
}

.new-recurring-mass-start-time-row:hover .actions {
  visibility: visible;
  cursor: pointer;
}

.new-recurring-mass-button-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

Button.edit-recurring-mass-button {
  margin-left: 10px;
}
