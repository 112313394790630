Button.intention-edit-modal-action-button {
  width: 100px;
  margin-left: 10px;
}

.intention-edit-modal-auto-move-container {
  width: 100%;
  max-height: 700px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
}

.intention-edit-modal-auto-move-prompt-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.intention-edit-modal-auto-move-prompt {
  font-size: 24px;
}

.intention-edit-modal-title {
  text-overflow: ellipsis;
  overflow: hidden;
}

.intention-edit-modal-mass-option {
  height: 50px;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 18px;
}

.selected-mass-option {
  border: 1px solid green;
  color: green;
}
